import type { BEWSBalanceModel, BalanceUpdate } from '@exchange/libs/balances/service/src';
import type { BEOrderModel } from '@exchange/libs/order/shared-model/src/lib/order-essentials';
import type { OrderTradesPair } from '@exchange/libs/order/shared-model/src/lib/order-model';
import type { BEFeeModel, BETradeModel } from '@exchange/libs/trade/trade-history/service/src';
import { wsSportChannelNames, WSIncomingEventTypes, type WSIncomingBasicMessagePayload } from '@exchange/libs/utils/wss/src/lib/websocket-spot-model';

export enum FundingUpdateType {
  FUNDS_DEPOSITED = 'FUNDS_DEPOSITED',
  FUNDS_WITHDRAWN = 'FUNDS_WITHDRAWN',
}

export enum TradingUpdateType {
  ORDER_CREATED = 'ORDER_CREATED',
  BOOKED = 'BOOKED',
  ORDER_REJECTED = 'ORDER_REJECTED',
  ORDER_FULLY_FILLED = 'ORDER_FULLY_FILLED',
  TRADE_SETTLED = 'TRADE_SETTLED',
  ORDER_CLOSED = 'ORDER_CLOSED',
  STOP_ORDER_TRIGGERED = 'STOP_ORDER_TRIGGERED',
  SETTLEMENT = 'SETTLEMENT',
}

export enum AccountUpdateType {
  TRADING_SETTINGS_CHANGED = 'TRADING_SETTINGS_CHANGED',
}

export interface AccountHistoryMessage extends WSIncomingBasicMessagePayload {
  type:
    | WSIncomingEventTypes.ACCOUNT_UPDATE
    | WSIncomingEventTypes.ACTIVE_ORDERS_SNAPSHOT
    | WSIncomingEventTypes.INACTIVE_ORDERS_SNAPSHOT
    | WSIncomingEventTypes.BALANCES_SNAPSHOT
    | WSIncomingEventTypes.ACTIVE_POSITIONS_SNAPSHOT;
}

/* eslint-disable camelcase */
export interface OrderSnapshot extends AccountHistoryMessage {
  channel_name: typeof wsSportChannelNames.ACCOUNT_HISTORY;
  type: WSIncomingEventTypes.ACTIVE_ORDERS_SNAPSHOT | WSIncomingEventTypes.INACTIVE_ORDERS_SNAPSHOT;
  orders: Array<OrderTradesPair>;
}

export interface ActiveOrderSnapshot extends OrderSnapshot {
  type: WSIncomingEventTypes.ACTIVE_ORDERS_SNAPSHOT;
}

export interface InactiveOrderSnapshot extends OrderSnapshot {
  type: WSIncomingEventTypes.INACTIVE_ORDERS_SNAPSHOT;
}

export interface PerpsPosition {
  symbol: string;
  direction: 'LONG' | 'SHORT';
  openVolume: string;
  openPriceSum: string;
}

export interface PerpsPositionUpdate {
  direction: 'LONG' | 'SHORT';
  open_volume: string;
  open_position_size: string;
  maintenance_margin: string;
}

export interface ActivePositionsSnapshot extends AccountHistoryMessage {
  type: WSIncomingEventTypes.ACTIVE_POSITIONS_SNAPSHOT;
  positions: Array<PerpsPosition>;
}

export interface BalancesSnapshot extends AccountHistoryMessage {
  channel_name: typeof wsSportChannelNames.ACCOUNT_HISTORY;
  type: WSIncomingEventTypes.BALANCES_SNAPSHOT;
  account_id: string;
  balances: Array<BEWSBalanceModel>;
}

export interface AccountUpdateMessage extends AccountHistoryMessage {
  channel_name: typeof wsSportChannelNames.ACCOUNT_HISTORY;
  type: WSIncomingEventTypes.ACCOUNT_UPDATE;
  account_id: string;
  update: AccountUpdate;
}

export enum AccountUpdateActivity {
  FUNDING = 'FUNDING',
  TRADING = 'TRADING',
  ACCOUNT = 'ACCOUNT',
}
export interface AccountUpdate {
  id: string;
  activity: AccountUpdateActivity;
  type: FundingUpdateType | TradingUpdateType | AccountUpdateType;
  time: string;
  sequence: number;
  account_id: string;
}

export interface WSSFundingUpdate extends AccountUpdate {
  activity: AccountUpdateActivity.FUNDING;
  type: FundingUpdateType;
  account_id: string;
  amount: string;
  currency_code: string;
  balances: Array<BalanceUpdate>;
}

export interface AccountTradingBase {
  account_holder: string;
  account_id: string;
  activity: AccountUpdateActivity.TRADING;
  order_id: string;
  sequence: number;
  time: string;
  time_nano: string; // fast only
}

export interface OrderClosedUpdate extends AccountTradingBase {
  balances: Array<BalanceUpdate>;
  filled_amount: string;
  id: string;
  order_book_sequence: number;
  reason: string;
  type: TradingUpdateType.ORDER_CLOSED;
}

export interface OrderCreatedUpdate extends AccountTradingBase {
  id: string;
  balances: Array<BalanceUpdate>;
  order: BEOrderModel;
  type: TradingUpdateType.ORDER_CREATED;
}
export interface OrderBookedUpdate extends AccountTradingBase {
  id: string;
  locked: BalanceUpdate;
  order: BEOrderModel;
  type: TradingUpdateType.BOOKED;
}

export interface OrderRejectedUpdate extends AccountTradingBase {
  balances: Array<BalanceUpdate>;
  filled_amount: string;
  id: string;
  reason: string;
  type: TradingUpdateType.ORDER_REJECTED;
}

export interface StopOrderTriggeredUpdate extends AccountTradingBase {
  order_book_sequence: number;
  type: TradingUpdateType.STOP_ORDER_TRIGGERED;
}

export interface TradeSettledUpdate extends AccountTradingBase {
  amount: string;
  balances: Array<BalanceUpdate>;
  fee: BEFeeModel;
  filled_amount: string;
  id: string;
  order: {
    amount: string;
    filled_amount: string;
  };
  trade: BETradeModel;
  type: TradingUpdateType.TRADE_SETTLED;
  position?: PerpsPositionUpdate;
}

export interface SettlementUpdate {
  instrument_code: string;
  direction: 'LONG' | 'SHORT';
  profit: string;
  account_ratio: string;
  maintenance_margin: string;
  type: TradingUpdateType.SETTLEMENT;
  time: string;
  time_nano: number;
}

export interface OrderFullyFilledUpdate extends AccountTradingBase {
  order: {
    amount: string;
    filled_amount: string;
  };
  type: TradingUpdateType.ORDER_FULLY_FILLED;
}

export type WSSTradingUpdate =
  | OrderClosedUpdate
  | OrderCreatedUpdate
  | OrderBookedUpdate
  | OrderFullyFilledUpdate
  | OrderRejectedUpdate
  | StopOrderTriggeredUpdate
  | TradeSettledUpdate;
/* eslint-enable camelcase */
